/*
アプリから見るヘルプページ
*/

import * as React from "react"
import { graphql } from "gatsby"
import "../helpPage.css"
import Layout from "../components/AppLayout"
import HelpContents from "./HelpContents"
import Seo from "../components/seo"

const HelpPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="ヘルプ" />
      <section className="AppHelp" id="pagetop">
        <div className="wrap">
          <HelpContents>読み込み中...</HelpContents>
        </div>
      </section>
    </Layout>
  )
}

export default HelpPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
