/*
アプリから見るページ用レイアウト
*/
import React from "react"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons'

const AppLayout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let footer

  footer = (
    <div className="wrap footer-wrap">
      <div className="PageTop"><AnchorLink href="#pagetop" offset="50">
        <FontAwesomeIcon icon={faArrowAltCircleUp} /> PAGE TOP</AnchorLink>
      </div>
    </div>
  )

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      {children}
      <footer className="app-footer">{footer}</footer>
    </div>
  )
}
export default AppLayout
